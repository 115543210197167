module.exports = [{
      plugin: require('../plugins/gatsby-plugin-primary-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-root-and-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true},
    },{
      plugin: require('../plugins/gatsby-plugin-cookie-information/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Philipson Söderberg","short_name":"Philipson Söderberg","start_url":"/","background_color":"#ffffff","theme_color":"#710EDB","icon":"src/images/philipson-soderberg/favicon.png","include_favicon":false,"display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"c116b121781cee8883f6c5ae587d7f22"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
